.auth-layout-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: cover;
    .auth-content {
        max-width: 660px;
        margin: auto;
    }
}

.auth-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.auth-logo {
    img {
        width: 100%;
        height: 60px;
    }
}

@media (min-width: 1024px) {
    .auth-layout-wrap {
        .auth-content {
            min-width: 660px;
        }
    }
}
@media (max-width: 767px) {
    .auth-layout-wrap {
        .auth-content {
            padding: 15px;
        }
    }
    .auth-right {
        padding: 40px 15px;
    }
}